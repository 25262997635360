
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import {
  ICenarioFiscal, ICenarioFiscalDefinicao, ICenarioFiscalDefinicaoCofins, ICenarioFiscalDefinicaoIcms, ICenarioFiscalDefinicaoIcmsInterestadual, ICenarioFiscalDefinicaoIcmsSubstituicao, ICenarioFiscalDefinicaoIcmsTipoAtividade, ICenarioFiscalDefinicaoIpi, ICenarioFiscalDefinicaoIpiTipoAtividade, ICenarioFiscalDefinicaoPis,
  ICenarioFiscalTipoMovimentacao,
} from '@/models/Entidades/Cadastros/Tributacoes/CenariosFiscais/ICenarioFiscal';
import ServicoCenarioFiscal from '@/servicos/Cadastros/Tributacoes/ServicoCenarioFiscal';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import storeSistema from '@/store/storeSistema';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import SelecionarTipoMovimentoComercial from '@/components/Compartilhados/SelecionarTipoMovimentoComercial.vue';
import CenarioFiscalDefinicoesEstadosIcms from '@/components/Cadastros/Tributacoes/CenariosFiscais/Definicoes/CenarioFiscalDefinicoesEstadosIcms.vue';
import CenarioFiscalDefinicaoIpi from '@/components/Cadastros/Tributacoes/CenariosFiscais/Definicoes/CenarioFiscalDefinicaoIpi.vue';
import CenarioFiscalDefinicaoPis from '@/components/Cadastros/Tributacoes/CenariosFiscais/Definicoes/CenarioFiscalDefinicaoPis.vue';
import CenarioFiscalDefinicaoCofins from '@/components/Cadastros/Tributacoes/CenariosFiscais/Definicoes/CenarioFiscalDefinicaoCofins.vue';
import { ETipoItemCenarioFiscal } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoItemCenarioFiscal';
import { ETipoImposto } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoImposto';
import CenarioFiscalInformacaoComplementar from '@/components/Cadastros/Tributacoes/CenariosFiscais/Definicoes/CenarioFiscalInformacaoComplementar.vue';
import { IDTOEstado } from '@/models/DTO/Cadastros/Localizacoes/IDTOEstado';
import ServicoEstado from '@/servicos/Cadastros/Localizacoes/ServicoEstado';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import CenarioFiscalExcecoes from '@/components/Cadastros/Tributacoes/CenariosFiscais/Definicoes/CenarioFiscalExcecoes.vue';
import { ETipoMovimentoComercial } from '@/core/models/Enumeradores/ETipoMovimentoComercial';

export default defineComponent({
  name: 'CenarioFiscalModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    RequisicaoModal,
    SelecionarEmpresa,
    SelecionarTipoMovimentoComercial,
    CenarioFiscalDefinicoesEstadosIcms,
    CenarioFiscalDefinicaoIpi,
    CenarioFiscalDefinicaoPis,
    CenarioFiscalDefinicaoCofins,
    CenarioFiscalInformacaoComplementar,
    CenarioFiscalExcecoes,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, filtrarPermissaoDadosUsuario, preencherPermissoesDados, preencherEmpresasComEstrategiaPermissaoDados, verificaConceitoParaApresentarEmpresas, apresentarMensagemSucesso,
      apresentarMensagemAlerta,
    } = useTelaBase();
    const {
      modalBase, defineTextoPadraoBotoes, apresentarBarraProgresso, ocultarBarraProgresso, sincronizarRegistro, apresentarRetornoRequisicao,
    } = useModalBase(props, emit);
    telaBase.identificadorRecurso = 'CADASTRO_CENARIO_FISCAL';
    telaBase.identificadorPermissao = 'PER_CADASTRO_CENARIO_FISCAL';

    const servicoCenarioFiscal = new ServicoCenarioFiscal();
    const servicoEstado = new ServicoEstado();
    const state = reactive({
      cenarioFiscal: {} as ICenarioFiscal,
      tiposMovimentos: [] as number[],
      tiposAtividadesIcms: [] as Array<number>[],
      tiposAtividadeIpi: [] as number[],
      estados: [] as IDTOEstado[],
      arquivosPdf: [] as IArquivoPdf[],
    });

    function criarDefinicaoIcms(estado: IDTOEstado) {
      const definicao = {} as ICenarioFiscalDefinicao;
      definicao.codigoCenarioFiscal = 0;
      definicao.codigoEstado = estado.codigo;
      definicao.codigoNcm = 0;
      definicao.codigoProdutoDefinicao = 0;
      definicao.siglaEstado = estado.sigla;
      definicao.tipoItem = ETipoItemCenarioFiscal.Geral;
      definicao.tipoImposto = ETipoImposto.Icms;
      definicao.definicaoIcms = {} as ICenarioFiscalDefinicaoIcms;
      definicao.definicaoIcms.codigoCfop = 0;
      definicao.definicaoIcms.tiposAtividade = [];
      definicao.definicaoIcms.contribuinteIcms = true;
      definicao.definicaoIcms.naoContribuinteIcms = true;
      definicao.definicaoIcms.pessoaSuperSimples = true;
      definicao.definicaoIcms.definicaoCst = true;
      let i = 1;
      while (i <= 6) {
        const tipoAtividade = {} as ICenarioFiscalDefinicaoIcmsTipoAtividade;
        tipoAtividade.tipoAtividade = i;
        definicao.definicaoIcms.tiposAtividade.push(tipoAtividade);
        i += 1;
      }
      definicao.definicaoIcms.calcularIcmsInterestadual = false;
      definicao.definicaoIcms.substituicao = {} as ICenarioFiscalDefinicaoIcmsSubstituicao;
      definicao.definicaoIcms.icmsInterestadual = {} as ICenarioFiscalDefinicaoIcmsInterestadual;
      return definicao;
    }

    function criarDefinicoesEstadoIcms() {
      state.cenarioFiscal.definicoesIcms = [] as ICenarioFiscalDefinicao[];
      state.tiposAtividadesIcms = [];
      state.estados.forEach((e) => {
        state.cenarioFiscal.definicoesIcms.push(criarDefinicaoIcms(e));
        state.tiposAtividadesIcms.push([] as number[]);
      });
    }

    function criarDefinicaoIpi() {
      state.cenarioFiscal.definicaoIpi = {} as ICenarioFiscalDefinicao;
      state.cenarioFiscal.definicaoIpi.codigo = 0;
      state.cenarioFiscal.definicaoIpi.codigoCenarioFiscal = 0;
      state.cenarioFiscal.definicaoIpi.codigoEstado = 0;
      state.cenarioFiscal.definicaoIpi.codigoNcm = 0;
      state.cenarioFiscal.definicaoIpi.codigoProdutoDefinicao = 0;
      state.cenarioFiscal.definicaoIpi.tipoItem = ETipoItemCenarioFiscal.Geral;
      state.cenarioFiscal.definicaoIpi.tipoImposto = ETipoImposto.Ipi;
      state.cenarioFiscal.definicaoIpi.definicaoIpi = {} as ICenarioFiscalDefinicaoIpi;
      state.cenarioFiscal.definicaoIpi.definicaoIpi.tiposAtividade = [];
      let i = 1;
      while (i <= 6) {
        const tipoAtividade = {} as ICenarioFiscalDefinicaoIpiTipoAtividade;
        tipoAtividade.tipoAtividade = i;
        state.cenarioFiscal.definicaoIpi.definicaoIpi.tiposAtividade.push(tipoAtividade);
        i += 1;
      }
      state.cenarioFiscal.definicaoIpi.definicaoIpi.especificarTipoAtividade = false;
    }

    function criarDefinicaoPis() {
      state.cenarioFiscal.definicaoPis = {} as ICenarioFiscalDefinicao;
      state.cenarioFiscal.definicaoPis.codigo = 0;
      state.cenarioFiscal.definicaoPis.codigoCenarioFiscal = 0;
      state.cenarioFiscal.definicaoPis.codigoEstado = 0;
      state.cenarioFiscal.definicaoPis.codigoNcm = 0;
      state.cenarioFiscal.definicaoPis.codigoProdutoDefinicao = 0;
      state.cenarioFiscal.definicaoPis.tipoItem = ETipoItemCenarioFiscal.Geral;
      state.cenarioFiscal.definicaoPis.tipoImposto = ETipoImposto.Pis;
      state.cenarioFiscal.definicaoPis.definicaoPis = {} as ICenarioFiscalDefinicaoPis;
    }

    function criarDefinicaoCofins() {
      state.cenarioFiscal.definicaoCofins = {} as ICenarioFiscalDefinicao;
      state.cenarioFiscal.definicaoCofins.codigo = 0;
      state.cenarioFiscal.definicaoCofins.codigoCenarioFiscal = 0;
      state.cenarioFiscal.definicaoCofins.codigoEstado = 0;
      state.cenarioFiscal.definicaoCofins.codigoNcm = 0;
      state.cenarioFiscal.definicaoCofins.codigoProdutoDefinicao = 0;
      state.cenarioFiscal.definicaoCofins.tipoItem = ETipoItemCenarioFiscal.Geral;
      state.cenarioFiscal.definicaoCofins.tipoImposto = ETipoImposto.Cofins;
      state.cenarioFiscal.definicaoCofins.definicaoCofins = {} as ICenarioFiscalDefinicaoCofins;
    }

    async function limparTela() {
      state.estados = await servicoEstado.obterTodos();
      state.cenarioFiscal = {} as ICenarioFiscal;
      state.cenarioFiscal.codigo = 0;
      state.cenarioFiscal.codigoEmpresa = storeSistema.getters.codigoEmpresaOperacao();
      state.cenarioFiscal.nome = '';
      state.cenarioFiscal.tiposMovimento = [];
      state.cenarioFiscal.excecoesIcms = [];
      state.cenarioFiscal.excecoesIpi = [];
      state.cenarioFiscal.excecoesPis = [];
      state.cenarioFiscal.excecoesCofins = [];
      state.cenarioFiscal.ativo = true;
      criarDefinicoesEstadoIcms();
      criarDefinicaoIpi();
      criarDefinicaoPis();
      criarDefinicaoCofins();
      state.tiposMovimentos = [];
    }

    async function preencherDefinicoes() {
      const definicoes = await servicoCenarioFiscal.obterTodasDefinicoes(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      const definicoesIcms = definicoes.filter((d) => d.tipoImposto === ETipoImposto.Icms);
      state.tiposAtividadesIcms = [];
      state.cenarioFiscal.excecoesIcms = [];
      definicoesIcms.forEach((d, index) => {
        definicoesIcms[index].visivel = true;
        definicoesIcms[index].definicaoIcms.definicaoCst = UtilitarioGeral.valorValido(definicoesIcms[index].definicaoIcms.cstIcms);
        const estado = state.estados.find((e) => e.codigo === d.codigoEstado);
        if (estado !== undefined) {
          definicoesIcms[index].codigoEstado = estado.codigo;
        }
        if (d.tipoItem !== ETipoItemCenarioFiscal.Geral) {
          state.cenarioFiscal.excecoesIcms.push(d);
        } else {
          const tiposAtividadeIcms = [] as number[];
          definicoesIcms[index].definicaoIcms.tiposAtividade.forEach((t) => {
            tiposAtividadeIcms.push(t.tipoAtividade);
          });
          state.tiposAtividadesIcms.push(tiposAtividadeIcms);
        }
      });
      state.cenarioFiscal.definicoesIcms = definicoesIcms.filter((d) => d.tipoItem === ETipoItemCenarioFiscal.Geral);
      if (!UtilitarioGeral.validaLista(state.cenarioFiscal.definicoesIcms)) {
        criarDefinicoesEstadoIcms();
      }

      const definicoesIpi = definicoes.filter((d) => d.tipoImposto === ETipoImposto.Ipi);
      const definicaoIpi = definicoesIpi.find((d) => d.tipoItem === ETipoItemCenarioFiscal.Geral);
      if (definicaoIpi !== undefined) {
        state.cenarioFiscal.definicaoIpi = definicaoIpi;
        state.tiposAtividadeIpi = [];
        definicaoIpi.definicaoIpi.tiposAtividade.forEach((t) => {
          state.tiposAtividadeIpi.push(t.tipoAtividade);
        });
      } else {
        criarDefinicaoIpi();
      }
      state.cenarioFiscal.excecoesIpi = [];
      definicoesIpi.forEach((d, index) => {
        definicoesIpi[index].visivel = true;
        if (d.tipoItem !== ETipoItemCenarioFiscal.Geral) {
          state.cenarioFiscal.excecoesIpi.push(d);
        }
      });

      const definicoesPis = definicoes.filter((d) => d.tipoImposto === ETipoImposto.Pis);
      const definicaoPis = definicoesPis.find((d) => d.tipoItem === ETipoItemCenarioFiscal.Geral);
      if (definicaoPis !== undefined) {
        state.cenarioFiscal.definicaoPis = definicaoPis;
      } else {
        criarDefinicaoPis();
      }
      state.cenarioFiscal.excecoesPis = [];
      definicoesPis.forEach((d, index) => {
        definicoesPis[index].visivel = true;
        if (d.tipoItem !== ETipoItemCenarioFiscal.Geral) {
          state.cenarioFiscal.excecoesPis.push(d);
        }
      });

      const definicoesCofins = definicoes.filter((d) => d.tipoImposto === ETipoImposto.Cofins);
      const definicaoCofins = definicoesCofins.find((d) => d.tipoItem === ETipoItemCenarioFiscal.Geral);
      if (definicaoCofins !== undefined) {
        state.cenarioFiscal.definicaoCofins = definicaoCofins;
      } else {
        criarDefinicaoCofins();
      }
      state.cenarioFiscal.excecoesCofins = [];
      definicoesCofins.forEach((d, index) => {
        definicoesCofins[index].visivel = true;
        if (d.tipoItem !== ETipoItemCenarioFiscal.Geral) {
          state.cenarioFiscal.excecoesCofins.push(d);
        }
      });
    }

    async function preencherTela() {
      state.cenarioFiscal = await servicoCenarioFiscal.obter(props.operacao.codigoRegistro);
      if (UtilitarioGeral.validaLista(state.cenarioFiscal.tiposMovimento)) {
        state.cenarioFiscal.tiposMovimento.forEach((item) => {
          state.tiposMovimentos.push(item.tipoMovimentacao);
        });
      }
      await preencherDefinicoes();
    }

    function obterDefinicoes() {
      state.cenarioFiscal.definicoes = [] as ICenarioFiscalDefinicao[];
      state.cenarioFiscal.definicoesIcms.forEach((d) => {
        const definicao = d;
        if (definicao.tipoItem === ETipoItemCenarioFiscal.Geral) {
          const indexEstado = state.estados.findIndex((e) => e.codigo === definicao.codigoEstado);
          definicao.definicaoIcms.tiposAtividade = [];
          state.tiposAtividadesIcms[indexEstado].forEach((t) => {
            const tipoAtividade = {} as ICenarioFiscalDefinicaoIcmsTipoAtividade;
            tipoAtividade.codigoCenarioFiscalDefinicaoIcms = definicao.definicaoIcms.codigo;
            tipoAtividade.tipoAtividade = t;
            definicao.definicaoIcms.tiposAtividade.push(tipoAtividade);
          });
        }

        state.cenarioFiscal.definicoes.push(definicao);
      });
      state.tiposAtividadeIpi.forEach((t) => {
        const tiposAtividade = {} as ICenarioFiscalDefinicaoIpiTipoAtividade;
        tiposAtividade.codigoCenarioFiscalDefinicaoIpi = state.cenarioFiscal.definicaoIpi.codigo;
        tiposAtividade.tipoAtividade = t;
        state.cenarioFiscal.definicaoIpi.definicaoIpi.tiposAtividade.push(tiposAtividade);
      });
      state.cenarioFiscal.definicoes.push(state.cenarioFiscal.definicaoIpi);
      state.cenarioFiscal.definicoes.push(state.cenarioFiscal.definicaoPis);
      state.cenarioFiscal.definicoes.push(state.cenarioFiscal.definicaoCofins);

      state.cenarioFiscal.excecoesIcms.forEach((d) => {
        state.cenarioFiscal.definicoes.push(d);
      });
      state.cenarioFiscal.excecoesIpi.forEach((d) => {
        state.cenarioFiscal.definicoes.push(d);
      });
      state.cenarioFiscal.excecoesPis.forEach((d) => {
        state.cenarioFiscal.definicoes.push(d);
      });
      state.cenarioFiscal.excecoesCofins.forEach((d) => {
        state.cenarioFiscal.definicoes.push(d);
      });
    }

    function obterTiposMovimentacao() {
      state.cenarioFiscal.tiposMovimento = [];
      state.tiposMovimentos.forEach((t) => {
        const tipoMovimentacao = {} as ICenarioFiscalTipoMovimentacao;
        tipoMovimentacao.codigoCenarioFiscal = state.cenarioFiscal.codigo;
        tipoMovimentacao.tipoMovimentacao = t;
        state.cenarioFiscal.tiposMovimento.push(tipoMovimentacao);
      });
    }

    function validarTipoMovimentacao() {
      if (!UtilitarioGeral.validaLista(state.tiposMovimentos)) {
        apresentarMensagemAlerta('É necessário inserir um tipo de Movimento');
        return false;
      }
      const combinacoesProibidas = [
        [ETipoMovimentoComercial.Vendas, ETipoMovimentoComercial.Compras],
        [ETipoMovimentoComercial.Vendas, ETipoMovimentoComercial.DevolucaoVendas],
        [ETipoMovimentoComercial.DevolucaoVendas, ETipoMovimentoComercial.DevolucaoCompras],
        [ETipoMovimentoComercial.Compras, ETipoMovimentoComercial.DevolucaoCompras],
      ];
      // eslint-disable-next-line no-restricted-syntax
      for (const [tipo1, tipo2] of combinacoesProibidas) {
        if (state.tiposMovimentos.includes(tipo1) && state.tiposMovimentos.includes(tipo2)) {
          apresentarMensagemAlerta('Os tipos de movimentação selecionados não podem ser selecionados no mesmo Cenário Fiscal.');
          return false;
        }
      }
      return true;
    }

    async function salvar() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      apresentarBarraProgresso();
      obterDefinicoes();
      obterTiposMovimentacao();

      if (!validarTipoMovimentacao()) {
        retorno.status = EStatusRetornoRequisicao.Alerta;
        retorno.mensagem = 'Verifique o Tipo de Movimentação';
        apresentarRetornoRequisicao(retorno);
        return;
      }

      if (state.cenarioFiscal.codigo === 0) {
        retorno = await servicoCenarioFiscal.incluir(state.cenarioFiscal);
      } else {
        retorno = await servicoCenarioFiscal.alterar(state.cenarioFiscal);
      }
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
          state.cenarioFiscal.codigo = retorno.codigoRegistro;
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      await limparTela();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, false);
        telaBase.permissaoDados = filtrarPermissaoDadosUsuario(props.operacao.empresaSelecionada);
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          await preencherTela();
        }
        verificaConceitoParaApresentarEmpresas();
        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      EPermissaoDados,
      UtilitarioGeral,
      UtilitarioMascara,
      limparTela,
      salvar,
    };
  },
});
